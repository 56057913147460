import { useDispatch, useSelector } from "react-redux";
import AddServiceModalPresentation from "./add-service-modal.component";
import { CarePlan, days, services, visits } from "./add-service-modal.constant";
import { useCallback, useEffect, useState } from "react";

import { isEmptyString } from "shared/methods/utilityFunctions";
import { AddServiceStateType } from "./add-service-modal.props";

import {
  addNewTocService,
  editTocService,
  getTocs,
  setIsTocValueChanged,
  setTocEditForm,
} from "state/features/toc/toc.slice";
import {
  getCommon,
  setIsOpenAddServiceModal,
} from "state/features/common/common.slice";
import {
  FACILITY_ERROR,
  LOS_LIMIT,
  LOS_VISIT_ERROR,
} from "pages/toc-detail/constants/index.constant";
import { CarePlanServices } from "shared/types/enum";

const AddServiceModal = () => {
  const dispatch = useDispatch();
  const { isOpenAddServiceModal } = useSelector(getCommon);
  const { tocEditForm, allFacilities, facilities, pacTypes } =
    useSelector(getTocs);
  const [isHandleSubmitPressed, setIsHandleSubmitPressed] = useState(false);
  const [showError, setShowError] = useState({
    service: false,
    provider: false,
    los: false,
    daysType: false,
  });
  const [addServiceState, setAddServiceState] = useState<AddServiceStateType>({
    service: { id: "", name: "" },
    provider: { id: "", name: "" },
    los: "",
    daysType: "",
    admissionDate: "",
  });

  const getDaysType = () => {
    switch (addServiceState.service.name) {
      case CarePlan.SNF:
      case CarePlan.IRF:
        return days;

      case CarePlan.HH:
      case CarePlan.MPT:
      case CarePlan.OPT:
        return visits;
      default:
        return visits;
    }
  };

  const checkIfLosValid = (value: string) => {
    if (!value) {
      return false;
    }
    const acuteLosNum = parseInt(value, 10);
    if (
      acuteLosNum < 1 ||
      acuteLosNum > LOS_LIMIT[addServiceState.service.name.toUpperCase()]
    ) {
      return false;
    }
    return true;
  };

  const resetErrorMessages = useCallback(() => {
    setShowError({
      service: isEmptyString(addServiceState.service.name),
      provider:
        isEmptyString(addServiceState.provider.id as string) ||
        addServiceState.provider.id?.toString() === "-1",
      los:
        isEmptyString(addServiceState.los) ||
        parseInt(addServiceState.los) <= 0,
      daysType: isEmptyString(addServiceState.daysType),
    });
  }, [
    addServiceState.daysType,
    addServiceState.los,
    addServiceState.provider.id,
    addServiceState.service.name,
  ]);

  const handleAddServiceStateChange = (
    field: string,
    value: { id: string; name: string }
  ) => {
    switch (field) {
      case "service":
        setAddServiceState({
          ...addServiceState,
          service: value,
        });
        break;
      case "provider":
        setAddServiceState({
          ...addServiceState,
          provider: value,
        });
        break;
      case "los":
        setAddServiceState({
          ...addServiceState,
          los: value.name,
          daysType: getDaysType(),
        });
        break;
      default:
        break;
    }
  };

  const handleDelete = () => {
    if (isOpenAddServiceModal.isEdit) {
      setAddServiceState({
        ...addServiceState,
        provider: { id: "", name: "" },
      });
    } else {
      setAddServiceState({
        service: { id: "", name: "" },
        provider: { id: "", name: "" },
        los: "",
        daysType: "",
        admissionDate: "",
      });
    }
  };

  const closeAddServiceModal = () => {
    dispatch(
      setIsOpenAddServiceModal({ ...isOpenAddServiceModal, isOpen: false })
    );
  };

  const checkMandatoryValues = () => {
    if (
      !isEmptyString(addServiceState.service.name) &&
      addServiceState.provider.id !== undefined &&
      !isEmptyString(addServiceState.provider.id) &&
      addServiceState.provider.id.toString() !== "-1" &&
      !isEmptyString(addServiceState.los) &&
      checkIfLosValid(addServiceState.los)
    ) {
      return true;
    }

    return false;
  };

  const handleSubmit = () => {
    setIsHandleSubmitPressed(true);
    if (checkMandatoryValues()) {
      dispatch(setIsTocValueChanged(true));
      dispatch(
        setTocEditForm({
          ...tocEditForm,
          isTocItemsFormDirty: true,
          isFormEmpty: false,
          losError: checkIfLosValid(addServiceState.los) ? "" : LOS_VISIT_ERROR,
        })
      );
      const facility = allFacilities.find(
        (item) =>
          addServiceState.provider.id &&
          addServiceState.provider.id.toString() === item.id.toString()
      );
      const selectedPacType = pacTypes.find(
        (pacType) =>
          pacType.itemShortName.toLowerCase() ===
          addServiceState.service.name.toLowerCase()
      );
      const payloadForTocService = {
        longName: addServiceState.service.name,
        providerId: addServiceState.provider.id,
        quantity: addServiceState.los,
        daysType: selectedPacType?.daysType,
        pacTypeName: addServiceState.service.name,
        providerName: facility?.providerName,
        pacTypeId: selectedPacType?.id,
        isPrefferedProvider: facility?.preferredProvider,
        admission: "after previous",
      };
      setIsHandleSubmitPressed(false);
      if (isOpenAddServiceModal.isEdit) {
        dispatch(editTocService(payloadForTocService));
      } else {
        dispatch(addNewTocService(payloadForTocService));
      }
      closeAddServiceModal();
      handleDelete();
    } else {
      setShowError({
        service: isEmptyString(addServiceState.service.name),
        provider:
          addServiceState.provider.id === undefined ||
          isEmptyString(addServiceState.provider.id) ||
          addServiceState.provider.id.toString() === "-1",
        los:
          isEmptyString(addServiceState.los) ||
          !checkIfLosValid(addServiceState.los),
        daysType: isEmptyString(addServiceState.daysType),
      });
    }
  };

  useEffect(() => {
    if (isOpenAddServiceModal.isEdit) {
      if (tocEditForm.editTocDetails) {
        const selectedFacilities =
          tocEditForm.editTocDetails.longName === CarePlanServices.EPISODE
            ? facilities
            : allFacilities;
        const selectedFacility = selectedFacilities.find(
          (facility) =>
            facility.id &&
            tocEditForm.editTocDetails &&
            tocEditForm.editTocDetails.providerId &&
            facility.id.toString() ===
              tocEditForm.editTocDetails?.providerId.toString()
        );
        setAddServiceState({
          provider: {
            name: tocEditForm.editTocDetails.providerId
              ? selectedFacility?.providerName
              : "-",
            id:
              selectedFacilities.length > 0
                ? selectedFacility?.id.toString()
                : "-1",
          },
          service: { name: tocEditForm.editTocDetails.longName, id: "" },
          los: tocEditForm.editTocDetails.quantity
            ? tocEditForm.editTocDetails.quantity.toString()
            : "",
          daysType: "",
          admissionDate:
            tocEditForm.editTocDetails.admissionDate !== undefined &&
            !isEmptyString(tocEditForm.editTocDetails.admissionDate)
              ? tocEditForm.editTocDetails.admissionDate
              : "-",
        });
      }
    }
  }, [
    isOpenAddServiceModal.isEdit,
    tocEditForm.editTocDetails,
    facilities,
    allFacilities,
  ]);

  useEffect(() => {
    resetErrorMessages();
  }, [resetErrorMessages]);

  return (
    <AddServiceModalPresentation
      closeAddServiceModal={closeAddServiceModal}
      handleSubmitOnAdd={() => {
        handleSubmit();
      }}
      services={services}
      handleAddServiceStateChange={handleAddServiceStateChange}
      addServiceState={addServiceState}
      showError={showError}
      isHandleSubmitPressed={isHandleSubmitPressed}
    />
  );
};

export default AddServiceModal;
