import { useSelector } from "react-redux";

import { AddServiceModalPresentationProps } from "./add-service-modal.props";
import { isEmptyString, validateNumber } from "shared/methods/utilityFunctions";
import "./add-service-modal.styles.scss";

import {
  addService,
  addText,
  editService,
  saveText,
} from "./add-service-modal.constant";
import { getCommon } from "state/features/common/common.slice";
import { CarePlanServices } from "shared/types/enum";
import Button from "components/Button";
import FacilityDropdown from "pages/toc-detail/facility-dropdown";
import { getTocs } from "state/features/toc/toc.slice";
import Modal from "components/Modal";
import CustomDropDown from "components/CustomDropdown";
import {
  LOS_LIMIT,
  LOS_VISIT_ERROR,
  PAC_TYPE,
} from "pages/toc-detail/constants/index.constant";
import { useMemo } from "react";

const AddServiceModalPresentation = (
  props: AddServiceModalPresentationProps
) => {
  const { isOpenAddServiceModal } = useSelector(getCommon);
  const {
    closeAddServiceModal,
    handleSubmitOnAdd,
    services,
    addServiceState,
    handleAddServiceStateChange,
    showError,
    isHandleSubmitPressed,
  } = props;
  const { facilities, allFacilities, tocEditForm, carePlanData, tocDetails } =
    useSelector(getTocs);

  const getFacilities = () => {
    if (isOpenAddServiceModal.isEdit) {
      if (
        tocEditForm &&
        tocEditForm.editTocDetails &&
        tocEditForm?.editTocDetails.longName === CarePlanServices.EPISODE
      ) {
        return facilities;
      }
      return allFacilities;
    } else {
      if (addServiceState.service.name === CarePlanServices.EPISODE) {
        return facilities;
      }
      return allFacilities;
    }
  };

  return (
    <Modal
      isOpen={isOpenAddServiceModal.isOpen}
      className="center add-service-popup"
    >
      <div className="add-service-container">
        <div className="header">
          {isOpenAddServiceModal.isEdit ? editService : addService}
        </div>
        <div className="content">
          <div className="field">
            <div className="heading">Service</div>
            <CustomDropDown
              placeholder="Select"
              dropDownMenuItems={services
                .map((service) => {
                  return {
                    key: service.key,
                    name: service.name,
                  };
                })
                .filter(
                  (item) =>
                    carePlanData.findIndex(
                      (carePlan) =>
                        carePlan.pacTypeName === item.name ||
                        carePlan.longName === item.name
                    ) === -1
                )}
              handleValueChanges={(option: any) => {
                handleAddServiceStateChange("service", {
                  id: option.key,
                  name: option.name,
                });
              }}
              defaultValue={{
                name: addServiceState.service.name,
                key: addServiceState.service.id?.toString(),
              }}
              idFieldName="key"
              dropDownBoxClass="result-dropdown"
              selectionClass="result-selection"
              dropDownContainerClass={`result-dropdown-container`}
              isDisabled={isOpenAddServiceModal.isEdit}
            />
            <div className="error-and-text-length-container">
              <div className="error-message">
                {isHandleSubmitPressed && showError.service
                  ? "Select Service"
                  : ""}
              </div>
            </div>
          </div>
          <div className="field">
            <div className="heading">Provider</div>
            <FacilityDropdown
              selectedOptionId={
                addServiceState.provider.id
                  ? parseInt(addServiceState.provider.id)
                  : -1
              }
              facilities={getFacilities()}
              isError={isHandleSubmitPressed && showError.provider}
              onChange={(providerId: number, providerName: string) => {
                handleAddServiceStateChange("provider", {
                  id: providerId.toString(),
                  name: providerName,
                });
              }}
            />
            {isHandleSubmitPressed && showError.provider && (
              <div className="error-message">Select Provider</div>
            )}
          </div>

          <div className="field visits-los">
            <div className="heading">LOS/Visits</div>
            <div className="acute-los">
              <input
                className={`acute-los-input ${
                  isHandleSubmitPressed && showError.los ? "error" : ""
                }`}
                type="string"
                placeholder=""
                name="acute-los"
                value={addServiceState.los}
                maxLength={2}
                disabled={false}
                onChange={(e: any) => {
                  if (
                    (validateNumber(e.target.value) ||
                      isEmptyString(e.target.value)) &&
                    e.target.value !== "0"
                  ) {
                    handleAddServiceStateChange("los", {
                      id: "",
                      name: e.target.value,
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (["e", ".", "-", "+"].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
              <span>days/visits</span>
            </div>
            {isHandleSubmitPressed &&
              showError.los &&
              isEmptyString(addServiceState.service.name) && (
                <div className="error-message">Enter LOS/Visits</div>
              )}
            {isHandleSubmitPressed &&
              showError.los &&
              !isEmptyString(addServiceState.service.name) && (
                <div className="error-message">
                  {LOS_VISIT_ERROR} and{" "}
                  {LOS_LIMIT[addServiceState.service.name.toUpperCase()]}
                </div>
              )}
          </div>
        </div>
        <div className="button-section">
          <div></div>
          <div>
            <Button
              text="Cancel"
              className="white-button cancel-button"
              onClick={closeAddServiceModal}
            />
            <Button
              text={isOpenAddServiceModal.isEdit ? saveText : addText}
              className="green-button reload-button"
              onClick={handleSubmitOnAdd}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddServiceModalPresentation;
